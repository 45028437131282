<template>
  <HeaderService
      :title="'Юридические услуги<br>IT и онлайн бизнесу'"
      :info="header.words"
      :person="header.person"
  />
  <div class="i-legal">
    <we-work-with
        :title="'Индустрии, с которыми мы работаем'"
        :subtitle="'Закрываем все юридические и смежные вопросы. Работаем <br> с компаниями в разных точках мира: в США, России, Европе, Китае, Кипре.'"
    />
    <about-clients
        id="clients"
        :reverse="'reverse'"
        :title='`Наши клиенты — <span class="text-gradient">лидеры IT-индустрии</span>`'
        :clients="clients"
        :bubbles="bubbles"
    />
    <help-slider :title="helpSlides.title" :data="helpSlides.slides"/>
    <team-price-block id="price"/>
    <we-do-it-right
        :info="weDoIt.info"
        :blocks="weDoIt.blocks"
        :dude="'right_dude.svg'"
    />
    <call-to-action
        :actionTitle="'Соберём для вас индивидуальное предложение'"
        :msg="'Проанализируем ваши задачи, подскажем оптимальную структуру и обеспечим организацию пространств для решения всех ваших задач под ключ.'"
        :btn-text="'Получить консультацию'"
    />
    <team-block
        id="team"
        :title='`Команда, которая <span class="text-gradient">умеет всё</span>`'
        :subtitle="'Вы получаете целую команду юристов - каждый с глубокой экспертизой в своей области. Все юристы имеют лучшее юридическое образование СПБГУ, МГУ. '"
        :team="team"
        :team-name="'ilegal'"
    />
    <business-tasks-block id="cases" :cases="cases"/>
    <best-conditions/>
  </div>
</template>

<script>
import WeWorkWith from "@/components/WeWorkWith";
import AboutClients from "@/components/AboutClients";
import CallToAction from "@/components/CallToAction";
import HelpSlider from "@/components/HelpSlider";
import BusinessTasksBlock from "@/components/BusinessTasksBlock";
import TeamBlock from "@/components/TeamBlock";
import BestConditions from "@/components/BestConditions";
import TeamPriceBlock from "@/components/TeamPriceBlock";
import WeDoItRight from "@/components/WeDoItRight";
import HeaderService from "@/components/HeaderService";

export default {
  name: "iLegal.vue",
  components: {
    HeaderService,
    WeWorkWith,
    AboutClients,
    CallToAction,
    HelpSlider,
    BusinessTasksBlock,
    TeamBlock,
    BestConditions,
    TeamPriceBlock,
    WeDoItRight
  },
  methods: {
    toSection(section) {
      if (section) {
        let target = document.getElementById(section).getBoundingClientRect().top
        target && window.scrollTo(target - 80, 0)
      } else {
        window.scrollTo(0, 0)
      }
    }
  },
  mounted() {
    this.toSection(this.section)
  },
  data() {
    return {
      header: {
        person: {
          name:'Наталья Юркова<br>Управляющий партнер i-Legal',
          img: 'yurkova.png'
        },
        words: [
          {
            title: 'Думаем за клиента ',
            content: 'Сами подсказываем фаундерам, что требует внимания и доработки'
          },
          {
            title: '10 000+ задач ',
            content: 'Помогли решить своим клиентам'
          },
          {
            title: '15 лет в бизнесе',
            content: 'Понимаем процессы разработки, бизнес модели, монетизации сервисов'
          },
          {
            title: 'Опыт и экспертиза',
            content: 'Знаем бизнес процессы IT компаний от сид раунда до раунда'
          }
        ]
      },
      weDoIt: {
        info: {
          title: 'Все сделаем правильно',
          content: 'Наладим постоянную связь и возьмем дела в работу еще до подписания договора.',

        },
        blocks: [
          {title: 'Организуем удобный формат общения', content: 'Общаемся удобными вам способами Телеграм, Ватцап, Вайбер, звонки, мэйл. Принимаем задачи 24\\7.'},
          {
            title: 'Подбирем специалистов с глубоким пониманием отрасли',
            content: 'Каждый юрист специализируется в узких от раслях права и разбирается в тонкостях. Что не может сделать ни один юрист в штате.'
          },
        ]
      },
      clients: [

        {name: 'Just AI', logo: 'just-ai.svg', url: '#'},
        {name: 'Data Solution', logo: 'data-solution.svg', url: '#'},
        {name: 'Embria', logo: 'embria.svg', url: '#'},
        {name: 'Trading View', logo: 'trading-view.svg', url: '#'},
        {name: 'i-Free', logo: 'i-free.svg', url: '#'},
        {name: 'На Ланч', logo: 'nalunch.svg', url: '#'},
        {name: 'Coin Keeper', logo: 'coin-keeper.svg', url: '#'},
        {name: 'Кошелёк', logo: 'wallet.svg', url: '#'},
        {name: 'Herocraft', logo: 'herocraft.svg', url: '#'},
      ],
      bubbles: [
        'AI', 'FinTech', 'Разработка игр', 'Облачные решения', 'Трейдинг', 'Телеком', 'FoodTech'
      ],
      helpSlides: {
        title: 'Каталог услуг',
        slides: [
          {
            title: 'Сопровождение M&A и инвестиционных проектов',
            content: {
              list: [
                'привлечение иностранных и российских инвестиций',
                'структурирование сделок по продаже и приобретению бизнеса и инвестиционных проектов',
                'юридический аудит (Due Diligence)',
                'разработка проектов инвестиционных и акционерных соглашений в различных юрисдикциях',
                'согласование сделок с антимонопольными органами',
              ],
            }
          },
          {
            title: 'Корпоративное право',
            content: {
              list: [
                'создание бизнеса с нуля',
                'реструктуризация существующего бизнеса',
                'инвестиционные договоры, соглашения акционеров, опционные соглашения',
                'юридическое сопровождение стартапов в РФ и иностранных юрисдикциях',
              ],
            }
          },
          {
            title: 'Интеллектуальная собственность',
            content: {
              list: [
                'правовые решения в области инноваций (искусственный интеллект, облачные решения и пр.)',
                'комплексная судебная и внесудебная защита прав на IP (представление интересов в Роспатенте, Палате по Патентным спорам)',
                'структурирование сделок с IP',
                'юридическая экспертиза прав на результаты интеллектуальной деятельности',
                'оформление прав работодателей на объекты IP, созданные сотрудниками',
                'регистрация программ для ЭВМ и баз данных в РФ и за рубежом',
                'оформление прав на доменное имя',
              ],
            }
          },
          {
            title: 'ФинТех Проекты',
            content: {
              list: [
                'структурирование ФинТех бизнеса',
                'правовая поддержка ФинТех проектов',
                'решения в области криптовалют',
                'консультирование по вопросам правовой чистоты ФинТех инноваций',
                'вопросы эмиссии и использования электронных денег',
                'регистрация и сопровождение деятельности микрофинансовых организаций',
                'консультирование в сфере законодательства о национальной платежной системе',
              ],
            }
          },
          {
            title: 'Электронная коммерция',
            content: {
              list: [
                'публичные оферты, договоры в электронной форме, правила пользования информационными ресурсами, политики безопасности',
                'правовые решения в области он-лайн торговли (применение он-лайн касс и т.д.)',
                'консультирование по вопросам обработки персональных данных',
                'вопросы регистрации электронных ресурсов как СМИ',
                'проверка соответствия бизнеса закону «О защите прав потребителей»',
                'правовая поддержка рекламных кампаний в социальных сетях',
                'консультирование по проектам на базе NFC',
                'налог на Google',
              ],
            }
          },
          {
            title: 'Антиоффшорное регулирование',
            content: {
              list: [
                'реструктуризация международного бизнеса с учетом требований антиоффшорного законодательства РФ',
                'анализ и минимизация рисков признания компаний КИК и налоговыми резидентами РФ',
                'безналоговая ликвидация иностранных компаний, перевод активов в РФ',
                'консультирование по созданию эффективного присутствия (substance) в иностранных юрисдикциях',
              ],
            }
          },
          {
            title: 'Открытие бизнеса в иностранных юрисдикциях',
            content: {
              list: [
                'открытие бизнеса за рубежом и его сопровождение',
                'структурирование бизнеса с использованием иностранных компаний',
                'регистрация, поддержка и ликвидация зарубежных компаний',
                'консультирование по применению договоров об избежании двойного налогообложения',
                'содействие в открытии банковских счетов за рубежом',
              ],
            }
          },
          {
            title: 'Налоговые вопросы',
            content: {
              list: [
                'консультирование по вопросам российского законодательства о налогах и сборах',
                'использование специальных режимов в РФ (УСН, Сколково, IT-компании и т.п.)',
                'налоговое планирование в иностранных юрисдикциях (рекомендации по структурированию международных холдинговых структур)',
                'анализ налоговых последствий сделок (в т.ч. международных)',
                'вопросы налогобложения VAT за рубежом (VAT-директива EU)',
              ],
            }
          },
          {
            title: 'Защита данных и коммерческой тайны',
            content: {
              list: [
                'консультирование по вопросам защиты данных',
                'разработка локальных нормативных актов',
                'рекомендации по процедурам защиты персональных данных',
                'консультирование по установлению режима коммерческой тайны',
                'разработка соглашений о неразглашении информации',
              ],
            }
          },
          {
            title: 'Трудовые вопросы',
            content: {
              list: [
                'консультирование по вопросам оформления трудовых отношений (IP, коммерческая тайна)',
                'аудит и разработка локальных нормативных актов',
                'досудебное урегулирование споров с работниками (IP, увольнение)',
                'оформление трудовых отношений с генеральным директором и руководящими работниками',
                'создание эффективных механизмов защиты работодателя (споры с работниками, проверяющими органами)',
                'сокращение штата и реструктуризация (минимизация рисков работодателя)',
              ],
            }
          },
          {
            title: 'Международные сделки',
            content: {
              list: [
                'разработка международных контрактов',
                'налоговое планирование',
                'консультирование по вопросам международного частного права',
                'валютный контроль',
                'оформление паспортов сделок',
              ],
            }
          },
        ]
      },
      team: [
        {
          name: 'Наталья Юркова',
          position: 'Управляющий партнер i-Legal',
          bio: 'M&A, международное частное право, корпоративное право, IP, «антиоффшорное» законодательство, налоговое законодательство России и Европы.',
          face: 'yurkova.jpg'
        },
        {
          name: 'Мария Ордовская',
          position: 'Ведущий юрист',
          bio: 'Договорное право, интеллектуальная собственность, административное право, законодательство о рекламе.',
          face: 'ordovskaya.jpg'
        },
        {
          name: 'Анастасия Кузьмина',
          position: 'Ведущий юрист ',
          bio: '«Антиоффшорное» законодательство, корпоративное право  законодательство о рекламе, законодательство о связи.',
          face: 'kuznetsova.jpg'
        },
        {
          name: 'Айлана Утнасунова',
          position: 'Ведущий юрист',
          bio: 'M&A, IP, «антиоффшорное» законодательство, корпоративное право  законодательство о рекламе,  о связи.',
          face: 'utnasunova.jpg'
        },
        {
          name: 'Анна Иванова',
          position: 'Ведущий юрист',
          bio: 'Международное частное право, IP, налоговое право, корпоративное право, законодательство о национальной платежной системе РФ,  о рекламе.',
          face: 'ivanova.jpg'
        },
        {
          name: 'Владимир Тараканов',
          position: 'Юрист',
          bio: 'Договорное право, интеллектуальная собственность, административное право, законодательство о рекламе.',
          face: 'tarakanov.jpg'
        },
        {
          name: 'Алина Танкова',
          position: 'Старший юрист',
          bio: 'M&A, IP,  поддержка иностранных компаний, «антиоффшорное» з-во, корпоративное право,  законодательство о рекламе,  о связи.',
          face: 'utnacunova.jpg'
        },
        {
          name: 'Вячеслав Тамулевичюс',
          position: 'Юрист',
          bio: 'Административное право, интеллектуальная собственность, законодательство о рекламе.',
          face: 'tamulevichus.jpg'
        },
      ],
      cases:{
        current:{
          case:{
            logo: 'wallet.svg',
            tittle: 'Кошелек сэкономил 35% затрат, отдавая на аутсорс i-Space все сервисные функции',
            content: 'Крупнейший финтех стартап в России аутсорсит компании i-Space все сервисные функции. Это позволяет не только экономить на операционных затратах, но и инвестировать время и деньги в развитие главных компетенций продукта.'
          },
          review:{
            face:'',
            name:'Филипп Шубин',
            status:'гендиректор Кошелек',
            content:[
              'С командой i-Legal мы работаем давно. За это время нам требовалась поддержка самых разных проектов, связанных с разработкой NFС-сервисов для банков, транспорта и ритейла. ',
              'i-Legal с нуля разработал для нас ряд сложных схем взаимодейтствия с участниками рынка и полностью обеспечивает юридическое сопровождение нашей деятельности.',
            ]
          }
        },
        tasks: [
          {
            logo: '',
            tittle: 'Минизация рисков с SEC',
            content:
                'Устранили неоднозначность в толковании токена, выпускаемого в рамках компьютерной игры. Снизили риск возможных претензий со стороны SEC и других государственных органов'
          },
          {
            logo: '',
            tittle: 'Разделение прав собственности',
            content: 'Помогли разрешить сложную задачу с разделением единого продукта (результата интеллектуальной деятельности) между совладельцами бизнеса по разным территориям. Разработали комплексную юридическую схему с привлечением тех специалистов'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.info {
  margin-bottom: 3em;
}

.info p {
  font-size: 25px;
}

.features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4.5em;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature img {
  margin-top: 1.5em;
}
</style>